<template>
  <div id="detaile">
    <a-spin size="large" :spinning="spinning" />
    <div class="bodyMain">
      <div v-if="isWe">
        <!-- 关于我们 -->
        <div class="topBg">
          <img :src="setLogo(strQ.img1)" alt="" />
        </div>
        <div v-html="strQ.content" v-if="strFlag"></div>
        <div v-else style="text-align: center">
          <img src="../assets/img/noData.png" alt="" />
        </div>
      </div>
      <div v-else>
        <!-- 详情 -->
        <div class="topBg">
          <!-- <img :src="setLogo(strQ.img1)" alt="" /> -->
          <h1>{{ strQ.title }}</h1>
          <p>
            <span>时间：{{ strQ.sendtime }}</span>
            <span>作者：{{ strQ.name }}</span>
          </p>
        </div>
        <div v-html="strQ.content" v-if="strFlag"></div>
        <div v-else style="text-align: center">
          <img src="../assets/img/noData.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detaile",
  components: {},

  created() {
    console.log("this.$route.query-1");
    console.log(this.$route.query);

    this.getData(this.$route.query);

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      strQ: "",
      spinning: true,
      strFlag: false,
      isWe: false,
    };
  },
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        // console.log("newVal");
        // console.log(newVal);
        // console.log("oldVal");
        // console.log(oldVal);

        this.getData(newVal);
      },
      deep: true,
    },
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getData(obj) {
      console.log("obj");
      console.log(obj);
      console.log(obj.isWe);
      let url;
      if (obj.isWe) {
        url = "/index";
        this.isWe = true;
      } else {
        url = "/detail";
        this.isWe = false;
      }

      console.log("getData");

      this.$api.getAction(url, obj).then((res) => {
        console.log("res");
        console.log(res);
        if (res.code == 0) {
          this.strQ = res.data;
          console.log("this.strQ");
          console.log(this.strQ.content == "" ? 1 : 2);
          if (this.strQ == "") {
            this.strFlag = false;
          } else {
            this.strFlag = true;
          }
        } else {
          this.$message.error(res.msg);
        }
        this.spinning = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
#detaile {
  .bodyMain {
    // border: 1px solid;
    margin: 0 auto;

    .topBg {
      text-align: center;
      padding-bottom: 20px;

      p {
        span {
          // padding-right: 100px;
          width: 250px;
          display: inline-block;
        }
      }

      img {
        width: 100%;
      }
    }
  }
  .ant-spin {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: fixed;
    padding-top: 30%;
  }
}
</style>
