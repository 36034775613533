<template>
  <div id="news">
    <div class="bodyMain">
      <div class="title">
        {{ news.title }}
        <div class="gradient"></div>
        <span class="more" @click="goLink()"
          >更多 <a-icon type="arrow-right"
        /></span>
      </div>

      <div class="content" v-if="news.content">
        <div
          class="item"
          v-for="(item, i) in news.content.slice(0, 4)"
          :key="i"
          @click="toDeatile(item.id, news.title)"
        >
          <img :src="setLogo(item.img)" alt="" class="fangda" />
          <p class="name" :title="item.title">{{ item.title }}</p>
          <div class="touxiang">
            <span>{{ item.name }}</span>
            <span class="fr">{{ item.sendtime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toDeatile } from "../assets/js/fun";
export default {
  name: "news",
  components: {},
  props: {
    news: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    toDeatile,
    goLink() {
      this.$router.push({
        path: "/list",
        query: { pid: this.news.pid, ty: this.news.ty },
      });
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
  },
};
</script>

<style scoped lang="less">
#news {
  background-color: #fff;

  .bodyMain {
    padding-bottom: 15px;
  }

  .content {
    overflow: hidden;

    .item {
      margin-right: 24px;
      width: calc((100% - (3 * 24px)) / 4);
      float: left;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 20px;
      color: #262626;
      overflow: hidden;
      cursor: pointer;
      background-color: rgba(245, 247, 253);

      img {
        // width: 312px;
        width: 100%;
        height: 175px;
      }

      .name {
        padding: 14px 16px 10px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
      }
      .touxiang {
        padding: 0px 16px 10px;
      }
    }

    .item:nth-child(4n) {
      margin-right: 0px;
    }
  }
}
</style>
