<template>
  <div id="mheader">
    <div class="headd 11">
      <div class="top">
        <img :src="setLogo(logoUrl)" alt="" class="logo" />
        <div class="" @click="isDshow = !isDshow">
          <span class="topName">关注青少年健康成长</span>
        </div>
      </div>
      <!-- <Banner class="bannn" /> -->
      <div class="bottom">
        <!--  :class="{open : isOpen}" -->
        <div class="left">
          <span
          class="itemText"
            v-for="(item, i) in daohang"
            :key="i"
            :class="i == activeIn ? 'act' : ''"
            @click="tapDaoHang(item, i)"
          >
            {{ item.catname }}
          </span>
        </div>
        <!-- <div class="right" @click="isOpen = !isOpen">
          <span>更多</span>
          <van-icon v-show="isOpen" name="arrow-up" />
          <van-icon v-show="!isOpen" name="arrow-down" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Banner from "../banner.vue";
export default {
  name: "mheader",
  components: {
    // Banner,
  },
  created() {},
  props: {
    logoUrl: {
      type: String,
      default: "",
    },
    daohang: {
      type: new Array(),
      default: [],
    },
  },
  data() {
    return {
      uurl: "",
      activeIn: 0,
      isDshow: false,
      isOpen: false,
      imgRul: "",
      webconfig: "",
    };
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    tapDaoHang(item, i) {
      // console.log(item);
      // console.log(i);
      this.activeIn = i;

      // 0	首页
      // 99	外部链接
      // 2  详情

      // return false
      // 当前路由信息
      console.log("当前路由信息---    " + this.$route.path);
      if (item.showtype == 0) {
        let pathNow = this.$route.path;
        if (pathNow != "/mindex") {
          this.$router.push({ path: "/mindex" });
        }
      } else if (item.showtype == 99) {
        window.open(item.linkurl);
      } else if (item.showtype == 12) {
        this.$router.push({
          path: "/mlistDown",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      } else if (item.showtype == 2) {
        this.$router.push({
          path: "/mdetaile",
          query: {
            pid: item.pid,
            ty: item.ty,
            catname: item.catname,
            isWe: true,
          },
        });
      } else {
        this.$router.push({
          path: "/mlist",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      }
    },
  },
};
</script>


<style scoped lang="less">
ul {
  padding: 0;
}
.headd {
  padding: 10px 20px;
  // height: 155px;
  // height: 125px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid #f6f6f6;

  img {
    width: 60px;
    height: 60px;
    float: left;
  }
  .top {
    text-align: center;
    line-height: 60px;

    span.topName {
      font-size: 20px;
      letter-spacing: 3px;
      font-weight: 600;
      color: #262626;
    }
  }
  .bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // width: 100%;
    // overflow: hidden;
    // overflow-x: auto;
    // white-space: nowrap;
    // .left{
    //   height: 34px;
    //   overflow: hidden;
    // }
    // .left.open{
    //   height: auto!important;
    // }
    .itemText {
      display: inline-block;
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 600;
      font-family: "微软雅黑";
      color: #71777d;
      // margin-right: 10px;
      width: 33%;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      // text-align: justify;
      // text-align-last: justify;
      // border-radius: 20px;
      // border: 1px solid;
      // margin-bottom: 20px;
    }
    .itemText.act {
      color: #e11;
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 600;
      font-family: "微软雅黑";
      color: #71777d;
      padding: 5px 0;
      width: 100px;
    }
  }
  .bottom::-webkit-scrollbar {
    display: none; /* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
  }
}

.title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  .name {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .more {
    font-size: 12px;
  }
}

.daohang {
  ul > li {
    line-height: 40px;
    background-color: #fff;
    height: 40px;
    border-bottom: 1px solid #ccc;
  }
  li.active {
    color: #e11;
    font-weight: 600;
  }
}
</style> 
