<template>
  <div class="swiper-container" id="swiper" v-if="lunbo.length > 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, i) in lunbo" :key="i">
        <img :src="setLogo(item.img1)" alt="" class="banner" />
      </div>

      <slot name="slid"></slot>
    </div>
  </div>
</template>
 
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";

export default {
  props: {
    lunbo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperDOM: null,
      // lunbo: [],
    };
  },
  watch: {
    lunbo: {
      handler(val) {
        console.log("lunbo-0", val);
        this.lunbo = val;
        this.$nextTick(() => {
          console.log("$nextTick");
          this.newSwiperFn();
        });
      },
      // 初次监听即执行
      immediate: true,
      // 值改变时执行监听
      deep: true,
    },
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    newSwiperFn() {
      new Swiper(".swiper-container", {
        // direction: "horizontal", // 垂直切换选项
        //mousewheel: true, //滚轮
        //自动开始
        autoplay: {
          //时间间隔
          delay: 3000,
          //*手动操作轮播图后不会暂停*
          disableOnInteraction: false,
        },
        // 循环模式选项
        loop: true,
      });
    },
  },
  components: {},
  created() {},
  mounted() {},
};
</script>
 
<style scoped lang="less">
.swiper-pagination {
  position: absolute;
  bottom: -0.1rem;
  right: 1rem;
}

.swiper-container ::v-deep .swiper-pagination-bullet-active {
  background-color: #fff;
}
.swiper-container ::v-deep .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}

#swiper {
  .swiper-slide {
    img {
      height: 180px;
      width: 100%;
    }
  }
}
</style>