console.log('公用方法')

export function goLink(val) {
  console.log(typeof val)
  console.log('setLogo--' + val)

}

export function toDeatile(id, title) {
  console.log(id);
  this.$router.push({ path: "/detaile", query: { id: id, catname: title } });
}