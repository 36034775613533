<template>
  <div id="mindex">
    <mHeader :logoUrl="webconfig.logo" :daohang="daohang" />

    <Banner class="bannn" />

    <div class="mkemu">
      <div class="title">
        <span class="name">{{ kemu.title }}</span>
      </div>

      <div
        class="list"
        v-for="(item, i) in kemu.content ? kemu.content.slice(0, 5) : []"
        :key="i"
        @click="toDeatile(item, kemu.title)"
      >
        <img :src="setLogo(item.img)" alt="" />
        <div class="right">
          <p class="up">
            {{ item.title }}
          </p>
          <p class="bottom">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="mkc">
      <div class="title">
        <span class="name">{{ hot.title }}</span>
        <span class="more" @click="toList(hot, hot.title)">查看更多 》</span>
      </div>

      <div class="list">
        <ul>
          <li
            v-for="(item, i) in hot.content ? hot.content.slice(0, 8) : []"
            :key="i"
            @click="toDeatile(item, hot.title)"
          >
            <img :src="setLogo(item.img)" alt="" class="" />
            <p class="up">{{ item.title }}</p>
            <p class="bottom">
              <span>{{ item.name }}</span>
              <span>{{ item.renshu }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="mabout">
      <div class="title">
        <span class="name"> {{ about.title }}</span>
        <span class="more" @click="toList(about, about.title)">
          查看更多 》
        </span>
      </div>

      <div
        class="list"
        v-for="(item, i) in about.content ? about.content.slice(0, 5) : []"
        :key="i"
        @click="toDeatile(item, about.title)"
      >
        <img :src="setLogo(item.img)" alt="" />
        <div class="right">
          <p class="up">
            {{ item.title }}
          </p>
          <p class="bottom">
            <span>{{ item.name }}</span>
            <span>{{ item.sendtime }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="mfc">
      <div class="title">
        <span class="name">{{ qiye.title }}</span>
        <span class="more" @click="toList(qiye, qiye.title)">查看更多 》</span>
      </div>

      <div class="listUp">
        <img
          :src="setLogo(item.img)"
          alt=""
          v-for="(item, i) in qiye.content.slice(0, 2)"
          :key="i"
          @click="toDeatile(item, qiye.title)"
        />
      </div>
      <div class="listBottom">
        <img
          :src="setLogo(item2.img)"
          alt=""
          v-for="(item2, index) in qiye.content.slice(2, 5)"
          :key="index"
          @click="toDeatile(item2, qiye.title)"
        />
      </div>
    </div>

    <div class="mhzhb">
      <div class="title">
        <span class="name">{{ hezuo.title }}</span>
      </div>

      <div class="list">
        <img
          :src="setLogo(item.img)"
          alt=""
          v-for="(item, i) in hezuo.content"
          :key="i"
        />
      </div>
    </div>

    <div class="myin">
      <img :src="setLogo(webconfig.footer)" alt="" />
    </div>

    <div class="mfooter">
      <p>{{ webconfig.indexabout }}</p>
      <p>{{ webconfig.copyright }}</p>
    </div>

    <div class="toLeftDiv">
      <transition-group class="phone" name="phone" tag="div" v-show="isShow">
        <div key="1">
          <div class="one">
            <a-icon type="phone" />
            <span>{{ webconfig.phone }}</span>
          </div>
          <div class="two">
            <!-- <a-icon type="wechat" /> -->
            <img :src="setLogo(webconfig.ewm)" alt="" />
          </div>
        </div>
      </transition-group>

      <!-- <a-icon
        type="message"
        class="mes"
        theme="filled"
        @click="isShow = !isShow"
      /> -->
      <!-- <a-icon type="phone" /> -->
      <div class="mes" theme="filled" @click="isShow = !isShow"></div>
    </div>
  </div>
</template>

<script>
import Banner from "../banner.vue";
import mHeader from "./mHeader.vue";

export default {
  name: "mindex",
  components: {
    Banner,
    mHeader,
  },
  created() {
    this.getDaoHang();
    this.getConfig();
    this.getMoKuai();
  },
  data() {
    return {
      mokuai: [],
      kemu: {},
      about: {},
      news: {},
      hot: {},
      qiye: {
        content: [],
      },
      hezuo: {},

      isShow: false,

      webconfig: {
        logo: sessionStorage.getItem("logoUrl") ||''
      },
      daohang: {},

      num: 5,
    };
  },
  methods: {
    toList(obj, title) {
      console.log("toList");
      this.$router.push({
        path: "/mlist",
        query: { pid: obj.pid, ty: obj.ty, catname: title },
      });
    },
    toDeatile(val, title) {
      console.log("toDeatile");
      this.$router.push({
        path: "/mdetaile",
        query: { id: val.id, catname: title },
      });
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getConfig() {
      this.$api.getAction("/webconfig").then((res) => {
        if (res.code == 0) {
          this.webconfig = res.data;

          sessionStorage.removeItem("logoUrl");
          sessionStorage.setItem("logoUrl", this.webconfig.logo);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    getDaoHang() {
      this.$api.getAction("/daohang").then((res) => {
        if (res.code == 0) {
          this.daohang = res.data.filter((item) => {
            console.log(item);
            return item.showtype != 99;
          });
          // 缓存导航信息
          sessionStorage.removeItem("daohang");
          sessionStorage.setItem("daohang", JSON.stringify(this.daohang));
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getMoKuai() {
      this.$api.getAction("/mokuai").then((res) => {
        if (res.code == 0) {
          let arr = res.data;
          for (const i in arr) {
            if (arr[i].mokuai == 1) {
              this.kemu = arr[i];
            } else if (arr[i].mokuai == 2) {
              this.about = arr[i];
              console.log(this.about);
            } else if (arr[i].mokuai == 3) {
              this.hot = arr[i];
            } else if (arr[i].mokuai == 4) {
              this.qiye = arr[i];
            } else if (arr[i].mokuai == 5) {
              console.log('data==',arr[i])
              let info = arr[i]
              let num = Math.floor(info.content.length/3)
              info.content = info.content.slice(0,num*3)
              this.hezuo = info;
            } else {
              console.log("else");
            }
          }
        } else {
          // this.$message.error(res.msg);
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>


<style scoped lang="less">
#mindex {
  background: #eff1f3;
  // padding: 0 15px;
}
ul {
  padding: 0;
  // margin: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  .name {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .more {
    font-size: 12px;
    color: #999;
  }
}

.mfc,
.mkemu,
.mkc,
.mhzhb,
.mabout {
  overflow: hidden;
  padding: 20px;
  margin: 20px 0;
}

.mkemu {
  background-color: #fff;
  // margin: 10px 0;

  .title {
    background: url(../../assets/img/title-bg-1.png) no-repeat;
    background-size: contain;
  }
  .list {
    padding: 10px 0;
    overflow: hidden;
  }
  img {
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  div.right {
    padding-left: 100px;
    p.bottom {
      color: #666666;
      font-size: 14px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    p.up {
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 600;
      color: #333;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mkc {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  .title {
    background: url(../../assets/img/title-bg-3.png) no-repeat;
    background-size: contain;
  }
  .list {
    padding: 10px 0;

    li {
      width: calc((100% - 10px) / 2);
      float: left;
      // text-align: center;
      padding-bottom: 10px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .up {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        padding: 5px 10px;
        background-color: #fff;
        color: #333;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        font-size: 12px;
        padding: 0 10px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    li:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.mhzhb {
  background-color: #fff;
  // margin: 10px 0;
  .title {
    background: url(../../assets/img/title-bg-2.png) no-repeat;
    background-size: contain;
  }

  .list {
    // padding-top: 5px;
    img {
      width: calc((100% - 20px) / 3);
      margin-right: 10px;
      margin-bottom: 10px;
    }
    img:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

.mabout {
  background-color: #fff;

  .title {
    background: url(../../assets/img/title-bg-2.png) no-repeat;
    background-size: contain;
  }
  .list {
    padding: 10px 0;
    overflow: hidden;
  }
  img {
    float: left;
    width: 80px;
    height: 80px;
  }

  div.right {
    padding-left: 100px;
    p.up {
      font-weight: 600;
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
      height: 60px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      color: #333;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    p.bottom {
      color: #666666;
      font-size: 12px;

      display: flex;
      justify-content: space-between;
    }
  }
}

.mfc {
  background-color: #fff;
  // margin: 10px 0;
  // padding-bottom: 20px;
  .title {
    background: url(../../assets/img/title-bg-3.png) no-repeat;
    background-size: contain;
  }
  img {
    margin-right: 10px;
    height: 80px;
  }

  .listUp {
    padding: 10px 0;

    img {
      width: calc((100% - 10px) / 2);
    }
    img:nth-child(2) {
      margin-right: 0px;
    }
  }
  .listBottom {
    img {
      width: calc((100% - 20px) / 3);
    }
    img:nth-child(3) {
      margin-right: 0px;
    }
  }
}

.mfooter {
  font-size: 12px;
  padding: 10px 20px;
  color: #fff;
  background-color: #4a4a4a;
  text-align: center;
}
.myin {
  width: 100%;
  img {
    width: 100%;
  }
}

.toLeftDiv {
  position: fixed;
  bottom: 70px;
  right: 0px;
  text-align: right;
  z-index: 10000;

  .mes {
    // font-size: 40px;
    // color: #ff9300;
    // padding-right: 10px;
    //
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #ff9300;
    float: right;
  }

  .phone {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;

    .one {
      text-align: center;
      font-size: 18px;

      .anticon {
        transform: rotate(90deg);
      }

      span {
        padding-left: 10px;
      }
    }
  }

  .two {
    text-align: center;

    img {
      width: 100px;
    }
  }

  // 离开前,进入后透明度是1
  .phone-leave,
  .phone-enter-to {
    opacity: 1;
  }
  .phone-leave-active,
  .phone-enter-active {
    //过度是2秒
    transition: all 1.5s;
  }
  .phone-leave-to,
  .phone-enter {
    opacity: 0;
  }
}

.bannn {
  margin-top: 151px;
}
</style> 
