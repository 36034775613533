<template>
  <div id="mheader">
    <div class="headd 22">
      <div class="top">
        <img :src="setLogo(logoUrl)" alt="" class="logo" />
        <div class="" @click="isDshow = !isDshow">
          <span class="topName">{{ catname }}</span>
          <a-icon type="caret-down" theme="filled" v-if="!isDshow" />
          <a-icon type="caret-up" theme="filled" v-else />
        </div>
      </div>

      <van-action-sheet
        v-model="isDshow"
        :actions="daohangArr"
        @select="tapDaoHang"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "mheader",
  components: {},

  created() {
    this.getDaoHang();
    let url =  sessionStorage.getItem("logoUrl")
    if(!url){
      console.log('logoUrl为空')
      this.getConfig()
    }else{
      this.logoUrl = url
    }
  },
  props: {
    catname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uurl: "",
      activeIn: 0,
      isDshow: false,
      imgRul: "",
      daohangArr: [],
      logoUrl:''
    };
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getConfig() {
      this.$api.getAction("/webconfig").then((res) => {
        if (res.code == 0) {
          let logoUrl =  res.data.logo;
          this.logoUrl = logoUrl
          sessionStorage.removeItem("logoUrl");
          sessionStorage.setItem("logoUrl", logoUrl);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    getDaoHang() {
      let val = sessionStorage.getItem("daohang")
      if(!val){
        this.$api.getAction("/daohang").then((res) => {
          if (res.code == 0) {
            let tempArr = res.data.filter((item) => {
              return item.showtype != 99;
           });
           for (let i in tempArr) {
            tempArr[i].name = tempArr[i].catname;
            this.daohangArr.push(tempArr[i]);
            }
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }else{
        this.daohangArr = [];
        let arr = JSON.parse(val);
        for (let i in arr) {
          arr[i].name = arr[i].catname;
          this.daohangArr.push(arr[i]);
        }
      }
      // console.log(this.daohangArr);
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.isDshow = false;
      console.log(item);

      this.tapDaoHang(item);
    },
    tapDaoHang(item) {
      this.isDshow = false;
      // 0	首页
      // 99	外部链接
      // 2  详情

      // return false
      // 当前路由信息
      console.log("当前路由信息---    " + this.$route.path);
      if (item.showtype == 0) {
        let pathNow = this.$route.path;
        if (pathNow != "/mindex") {
          this.$router.push({ path: "/mindex" });
        }
      } else if (item.showtype == 99) {
        window.open(item.linkurl);
      } else if (item.showtype == 12) {
        this.$router.push({
          path: "/mlistDown",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      } else if (item.showtype == 2) {
        this.$router.push({
          path: "/mdetaile",
          query: {
            pid: item.pid,
            ty: item.ty,
            isWe: true,
            catname: item.catname,
          },
        });
      } else {
        this.$router.push({
          path: "/mlist",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      }
    },
  },
};
</script>


<style scoped lang="less">
.headd {
  padding: 10px 20px;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid #f6f6f6;

  img {
    width: 60px;
    height: 60px;
    float: left;
  }
  .top {
    text-align: center;
    line-height: 60px;

    span.topName {
      font-size: 20px;
      letter-spacing: 3px;
      font-weight: 600;
      color: #262626;
    }
  }
  .bottom {
    // width: 100%;
    // overflow: hidden;
    // overflow-x: auto;
    // white-space: nowrap;

    span {
      display: inline-block;
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 600;
      font-family: "微软雅黑";
      color: #71777d;
      // margin-right: 10px;
      width: 33%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // border-radius: 20px;
      // border: 1px solid;
      // margin-bottom: 20px;
    }
    span.act {
      color: #e11;
    }
  }
  .bottom::-webkit-scrollbar {
    display: none; /* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
  }
}

.title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  .name {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .more {
    font-size: 12px;
  }
}

.daohang {
  ul > li {
    line-height: 40px;
    background-color: #fff;
    height: 40px;
    border-bottom: 1px solid #ccc;
  }
  li.active {
    color: #e11;
    font-weight: 600;
  }
}
</style> 
