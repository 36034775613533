// 主要为 对应的方法
import axios from './axios'

function getAction(url, params) {
  let str = '?'
  if (params && JSON.stringify(params) != '{}') {
    for (const key in params) {
      str += key + '=' + params[key] + '&'
    }
    str = str.substr(0, str.length - 1)
  }
  return axios.get(url + str, params)
}

function postAction(url, params) {
  return axios.post(url, params)
}

function delAction(url, str, params) {
  console.log(url)
  console.log(str)
  console.log(params)
  if (str) {
    return axios.delete(url + '/' + str, params)
  } else {
    return axios.delete(url, { data: params })
  }
}

function putAction(url, str, params) {
  return axios.put(url + '/' + str, params)
}

export default { getAction, postAction, putAction, delAction }
