<template>
  <div id="list">
    <div class="bodyMain">
      <!-- <a-pagination :default-current="page" :total="total" @change="onChange" /> -->
      <!-- <div class="title">安全资讯</div> -->
      <a-input-search
        placeholder="请输入关键词搜索"
        @search="onSearch"
        style="width: 400px"
        allowClear
        class="search-input"
      />
      <div
        v-for="(item, i) in arr"
        :key="i"
        class="all"
        @click="toDetaile(item)"
      >
        <img :src="setLogo(item.img1)" alt="" />

        <div class="rightCon">
          <p class="rightTitle">{{ item.title }}</p>
          <p class="rightTime">
            {{ item.sendtime }}
          </p>
          <p class="rightContent" :title="item.description">
            {{ item.description }}
          </p>
        </div>
      </div>

      <a-pagination
        :default-current="page"
        :total="total"
        @change="onChange"
        :hideOnSinglePage="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  components: {},
  data() {
    return {
      query: {
        keyword: "",
      },
      arr: [],
      page: 1,
      total: 10,
    };
  },
  //监听路由变化
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        // console.log("newVal");
        // console.log(newVal);
        // console.log("oldVal");
        // console.log(oldVal);
        this.query = newVal;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    console.log(document.documentElement.clientWidth);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.query = Object.assign(this.$route.query, this.query);
    this.getList();
  },
  mounted() {},
  methods: {
    onSearch(val) {
      this.query.keyword = val;
      this.getList();
    },
    onChange(cu) {
      console.log(cu);
      this.page = cu;
      this.query.page = this.page;
      this.getList();
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    toDetaile(item) {
      console.log(item);
      this.$router.push({
        path: "/detaile",
        query: { id: item.id, catname: this.query.catname },
      });
    },
    getList() {
      this.$api.getAction("/index", this.query).then((res) => {
        console.log("res");
        console.log(res);
        if (res.code == 0) {
          this.arr = res.data.list;
          this.total = parseInt(res.data.pagearr.total);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
#list {
  .bodyMain {
    margin: 0 auto;
    .search-input {
      margin-bottom: 40px;
    }
    .title {
      // font-size: 20px;
      font-weight: 600;
      // border-bottom: 1px solid #e8e8e8;
    }

    .all {
      display: flex;
      cursor: pointer;
      margin-bottom: 20px;

      img {
        width: 200px;
        height: 150px;
        border-radius: 10px;
      }

      .rightCon {
        margin-left: 30px;
        border-bottom: 1px solid #e8e8e8;
        width: calc(100% - 230px);

        p.rightTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          padding-bottom: 15px;
          font-size: 20px;
          font-weight: 600;
          color: #333;
        }

        p.rightContent {
          line-height: 25px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .rightTime {
          padding-bottom: 15px;
        }
      }
    }

    .ant-pagination {
      text-align: right;
    }
  }
  @media screen and (max-width: 1440px) {
    .bodyMain {
      padding: 20px 0;
      .search-input {
        margin-bottom: 20px;
      }
      .all {
        margin-bottom: 10px;
        img {
          width: 106px;
          height: 80px;
          border-radius: 10px;
        }
        .rightCon {
          p {
            padding-bottom: 5px !important;
          }
          p.rightTitle {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
