<template>
  <div>
    <m-Header2 :catname="catname" />
    <div id="mDetaile">
      <!-- <div class="title">{{ strQ.tyname }}</div> -->

      <van-loading vertical color="#0094ff" v-if="isLoading">
        <template #icon>
          <van-icon name="star-o" size="30" />
        </template>
        加载中...
      </van-loading>
      <div class="bodyMain" v-else>
        <div v-if="isWe">
          <div class="topBg">
            <img :src="setLogo(strQ.img1)" alt="" />
          </div>
          <div v-html="strQ.content" v-if="strFlag"></div>
          <div v-else style="text-align: center">
            <img src="../../assets/img/noData.png" alt="" />
          </div>
        </div>
        <div v-else>
          <div class="topBg">
            <h1>{{ strQ.title }}</h1>
            <p>
              <span>时间：{{ strQ.sendtime }}</span>
              <span>作者：{{ strQ.name }}</span>
            </p>
          </div>
          <div v-html="strQ.content" v-if="strFlag"></div>
          <div v-else style="text-align: center">
            <img src="../../assets/img/noData.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mHeader2 from "./mHeader2.vue";

export default {
  name: "mDetaile",
  components: {
    mHeader2,
  },

  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // console.log("this.$route.query-1");
    // console.log(this.$route.query);

    this.catname = this.$route.query.catname;

    this.getData(this.$route.query);
  },
  data() {
    return {
      strQ: "",
      spinning: true,
      strFlag: false,
      isWe: false,
      catname: "",
      isLoading: true,
    };
  },
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        // console.log("newVal");
        // console.log(newVal);
        // console.log("oldVal");
        // console.log(oldVal);
        this.getData(newVal);
      },
      deep: true,
    },
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getData(obj) {
      console.log("obj");
      console.log(obj);
      console.log(obj.isWe);
      let url;
      if (obj.isWe) {
        url = "/index";
        this.isWe = true;
      } else {
        url = "/detail";
        this.isWe = false;
      }

      console.log("getData");

      this.$api.getAction(url, obj).then((res) => {
        console.log("res");
        console.log(res);
        if (res.code == 0) {
          this.strQ = res.data;
          console.log("this.strQ");
          console.log(this.strQ);
          console.log(this.strQ.content == "" ? 1 : 2);
          if (this.strQ == "") {
            this.strFlag = false;
          } else {
            this.strFlag = true;
          }
        } else {
          // this.$message.error(res.msg);
          this.$toast.fail(res.msg);
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
#mDetaile {
  padding: 85px 20px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .bodyMain {
    padding-top: 10px;
    margin: 0 auto;
    width: auto !important;

    .topBg {
      text-align: center;
      padding-bottom: 20px;
      width: 100%;
      // max-width: 300px !important;

      p {
        span {
          width: auto !important;
        }
        span + span {
          padding-left: 20px;
        }
      }
    }
    ::v-deep img {
      width: 100% !important;
      height: auto;
    }
    ::v-deep video {
      width: 100% !important;
      height: auto;
    }
  }

  .ant-spin {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: fixed;
    padding-top: 30%;
  }
}
</style>
