<template>
  <div id="headerLayOut">
    <div class="left">
      <div
        class="logocontent"
        :style="{ backgroundImage: `url(${setLogo(webconfig.logoBG)})` }"
      >
        <!-- <img :src="setLogo(webconfig.logoBG)" alt="" class="logo" /> -->
      </div>
    </div>
    <div class="headerbottom">
      <div class="middle bodyMain">
        <div
          :class="['topTab', flag == index ? 'active' : '']"
          @click="toLink(item, index)"
          v-for="(item, index) in daohang"
          :key="index"
        >
          {{ item.catname }}
        </div>
      </div>
      <div class="right" style="width: 112px">
        <a-dropdown overlayClassName="changeColor">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            切换主题色 <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item key="blue" class="blue">
              <a href="javascript:;">蓝色</a>
            </a-menu-item>
            <a-menu-item key="org" class="org">
              <a href="javascript:;">橙色</a>
            </a-menu-item>
            <a-menu-item key="red" class="red">
              <a href="javascript:;">红色</a>
            </a-menu-item>
            <a-menu-item key="green" class="green">
              <a href="javascript:;">绿色</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerLayOut",
  props: {
    webconfig: Object,
  },
  data() {
    return {
      flag: sessionStorage.getItem("shouFlag")
        ? sessionStorage.getItem("shouFlag")
        : "0",
      daohang: [],
    };
  },
  created() {
    this.getDaoHang();
  },
  methods: {
    getDaoHang() {
      this.$api.getAction("/daohang").then((res) => {
        if (res.code == 0) {
          // console.log(res)
          this.daohang = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    //模拟锚点跳转
    goAnchor(selector) {
      //参数selector是id选择器（#anchor14）
      document.querySelector("#" + selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    toLink(item, i) {
      this.flag = i;
      sessionStorage.setItem("shouFlag", i);
      console.log(item);
      // 0	首页
      // 99	外部链接
      // 2  详情

      // return false

      if (item.showtype == 0) {
        this.$router.push({ path: "/shouye" });
      } else if (item.showtype == 99) {
        window.open(item.linkurl);
      } else if (item.showtype == 12) {
        this.$router.push({
          path: "/listDown",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      } else if (item.showtype == 2) {
        this.$router.push({
          path: "/detaile",
          query: {
            pid: item.pid,
            ty: item.ty,
            catname: item.catname,
            isWe: true,
          },
        });
      } else {
        // this.$message.info("暂无对应页面");
        this.$router.push({
          path: "/list",
          query: { pid: item.pid, ty: item.ty, catname: item.catname },
        });
      }
    },
    onClick(key) {
      // 改变主题色
      localStorage.setItem("styleName", key.key);

      this.$emit("changeStyle", key.key);
    },
  },
};
</script>

<style scoped lang="less">
#headerLayOut {
  // display: flex;
  // justify-content: space-evenly;
  // line-height: 72px;

  position: fixed;
  top: 0;
  z-index: 1000;
  background: #fff;
  width: 100%;

  .bodyMain {
    padding: 0;
    margin: 0;
  }

  .left {
    .logo {
      width: 100%;
      height: 62px;
      vertical-align: middle;
    }
  }

  .middle {
    display: flex;
    font-weight: 600;

    .topTab {
      cursor: pointer;
      margin-right: 40px;
      color: #595959;
      font-size: 16px;
      padding-bottom: 8px;
    }
    .topTab.active {
      font-weight: 600;
    }
    .topTab:last-child {
      margin-right: 0px;
    }
  }

  .right {
    .ant-dropdown-link,
    .ant-dropdown-link:hover {
      color: #333;
    }
  }
}
.ant-dropdown {
  .blue.ant-dropdown-menu-item:hover {
    background-color: #4687fe;
  }
  .org.ant-dropdown-menu-item:hover {
    background-color: #ff7700;
  }
  .red.ant-dropdown-menu-item:hover {
    background-color: #ff5967;
  }
  .green.ant-dropdown-menu-item:hover {
    background-color: #39a065;
  }
}

.headerbottom {
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  margin: 0 auto;
  // line-height: 40px;
}

@media screen and (max-width: 1500px) {
  .headerbottom {
    display: flex;
    justify-content: space-between;
    padding: 0 0;
    margin: 0 auto;
    // line-height: 40px;
  }
}
.logocontent {
  // width: 1321px;
  // margin: 0 auto;
  margin-bottom: 20px;
  background-size: cover;
  height: 82px;
  .logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
  }
}
</style>
