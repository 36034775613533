import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import './assets/css/all.less'
import api from './api/api'

import moment from 'moment';

Vue.prototype.$api = api
Vue.prototype.$moment = moment

Vue.config.productionTip = false

Vue.use(Antd)


// 
import { Button } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Toast } from 'vant';
import { List } from 'vant';
import { ActionSheet } from 'vant';
import { Loading } from 'vant';
import { Search } from 'vant';
import { Icon } from 'vant';

// 
import 'vant/lib/index.css';
// 
Vue.prototype.$toast = Toast
// 
Vue.use(ActionSheet);
Vue.use(List);
Vue.use(Button);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(Search);
Vue.use(Icon);
// 



new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
