<template>
  <div id="hezuo">
    <div class="bodyMain">
      <div class="title">
        {{ hezuo.title }}
        <div class="gradient"></div>
        <!-- <span class="more">更多 <a-icon type="arrow-right" /></span> -->
      </div>

      <div class="content">
        <div class="item" v-for="(item, i) in hezuo.content" :key="i">
          <a :href="item.linkurl" target="_blank">
            <img :src="setLogo(item.img)" alt="" class="fangda" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hezuo",
  components: {},
  props: {
    hezuo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setLogo(val) {
      // console.log(val)
      return process.env.VUE_APP_PIC_URL + val;
      // return val
    },
  },
};
</script>

<style scoped lang="less">
#hezuo {
  .content {
    overflow: hidden;
  }

  .item {
    margin-right: 10px;
    float: left;
    background-color: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
    width: 123px;
    img {
      width: 123px;
      height: 80px;
      object-fit: cover;
    }
  }
  .item:nth-child(10n) {
    margin-right: 0px;
  }
}
</style>
