<template>
  <div id="hot">
    <div class="bodyMain">
      <div class="title">
        {{ hot.title }}
        <div class="gradient"></div>
        <span class="more" @click="goLink()"
          >更多 <a-icon type="arrow-right"
        /></span>
      </div>

      <div class="content">
        <div
          class="item"
          v-for="(item, i) in hot.content ? hot.content.slice(0, 8) : []"
          :key="i"
          @click="toDeatile(item.id, hot.title)"
        >
          <img :src="setLogo(item.img)" alt="" class="fangda" />
          <p class="name" :title="item.title">{{ item.title }}</p>
          <div class="touxiang">
            <!-- <img :src="setLogo(item.touxiang)" alt="" /> -->
            <img
              :src="
                item.touxiang
                  ? setLogo(item.touxiang)
                  : '../assets/img/noPer.png'
              "
              alt=""
            />
            <span>{{ item.name }}</span>
            <span class="fr">{{ item.renshu }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toDeatile } from "../assets/js/fun";
export default {
  name: "hot",
  components: {},
  props: {
    hot: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toDeatile,
    goLink() {
      this.$router.push({
        path: "/list",
        query: { pid: this.hot.pid, ty: this.hot.ty },
      });
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
  },
};
</script>

<style scoped lang="less">
#hot {
  .bodyMain {
    padding-bottom: 15px;
  }

  .content {
    overflow: hidden;

    .item {
      margin-right: 24px;
      width: calc((100% - (3 * 24px)) / 4);
      float: left;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 20px;
      color: #262626;
      overflow: hidden;
      cursor: pointer;

      img {
        // width: 312px;
        width: 100%;
        height: 175px;
      }
      .touxiang {
        padding: 0px 16px 16px;
        font-size: 12px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin-right: 6px;
        }

        .fr {
          color: #8c8c8c;
        }
      }
      .name {
        padding: 14px 16px 10px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
      }
    }
    .item:nth-child(4n) {
      margin-right: 0px;
    }
  }
}
</style>
