<template>
  <div id="shouye">
    <Banner />

    <Kemu :kemu="kemu" />
    <!-- <About :about="about" /> -->
    <News :news="news" />
    <Hot :hot="hot" />
    <Qiye :qiye="qiye" />
    <Hezuo :hezuo="hezuo" />
  </div>
</template>

<script>
// import About from './about'
import Hot from "./hot";
import News from "./news";
import Qiye from "./qiye";
import Hezuo from "./hezuo";
import Banner from "./banner";
import Kemu from "./kemu";

export default {
  name: "shouye",
  components: {
    Banner,
    Kemu,
    // About,
    Hot,
    News,
    Qiye,
    Hezuo,
  },
  created() {
    console.log("process.env.VUE_APP_API_BASE_URL");
    console.log(process.env.VUE_APP_API_BASE_URL);
    this.getMoKuai();
  },
  data() {
    return {
      mokuai: [],
      kemu: {},
      // about: {},
      news: {},
      hot: {},
      qiye: {
        content: [],
      },
      hezuo: {},
    };
  },
  methods: {
    getMoKuai() {
      this.$api.getAction("/mokuai").then((res) => {
        // console.log(res)
        if (res.code == 0) {
          // console.log(typeof res.data)
          let arr = res.data;
          for (const i in arr) {
            if (arr[i].mokuai == 1) {
              this.kemu = arr[i];
            } else if (arr[i].mokuai == 2) {
              // this.about = arr[i];
              this.news = arr[i];
            } else if (arr[i].mokuai == 3) {
              this.hot = arr[i];
            } else if (arr[i].mokuai == 4) {
              this.qiye = arr[i];
            } else if (arr[i].mokuai == 5) {
              this.hezuo = arr[i];
            } else {
              console.log("else");
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
