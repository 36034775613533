<template>
  <div :class="[styleName, fullName]">
    <Header @changeStyle="changeStyleFn" :webconfig="webconfig" />
    <div class="main" :style="{ 'min-height': minHeight }">
      <router-view />
    </div>
    <Footer :webconfig="webconfig" />

    <div class="toMe">
      <div class="toRightDiv">
        <!-- <div class="toRight"><a-icon type="phone" /></div> -->
        <div class="toRightTell"><span class="name">联系电话</span></div>
      </div>

      <div class="toLeftDiv">
        <!-- <div class="toLeft">
          <a-icon type="phone" />
        </div>
        <div class="tell">
          <p class="name">联系电话</p>
          <p>{{ webconfig.phone }}</p>
        </div>
        <div class="toBottom">
          <a-icon type="wechat" />
          <img :src="setLogo(webconfig.ewm)" alt="" />
        </div> -->
        <div class="one">
          <a-icon type="phone" />
          <span>联系电话</span>
        </div>
        <div class="two">{{ webconfig.phone }}</div>
        <div class="three">
          <!-- <a-icon type="wechat" />  -->
          <img :src="setLogo(webconfig.ewm)" alt="" />
        </div>

        <div class="jiao"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  created() {
    this.getConfig();

    console.log("document.body.clientWidth");
    console.log(document.body.clientWidth);
  },
  data() {
    return {
      fullName: document.body.clientWidth < 1800 ? "notFull" : "",

      styleName: localStorage.getItem("styleName")
        ? localStorage.getItem("styleName")
        : "blue",
      webconfig: {},
      minHeight: document.body.clientHeight - 400 + "px",
    };
  },
  methods: {
    changeStyleFn(val) {
      // console.log('changeStyle')
      // console.log(val)
      this.styleName = val;
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getConfig() {
      this.$api.getAction("/webconfig").then((res) => {
        // console.log(res)
        if (res.code == 0) {
          this.webconfig = res.data;
          console.log(this.webconfig, 'this.we')
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  background-color: #f5f7fd;
  padding-top: 136px;
}
.toMe {
  position: fixed;
  right: 15px;
  top: 60%;
  z-index: 9999999;
  cursor: pointer;

  .toRightDiv {
    float: right;
    padding-top: 30px;

    .toRightTell {
      border: 1px solid;
      padding: 15px 8px 5px 5px;
      border-radius: 5px;
      writing-mode: vertical-rl;
      letter-spacing: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
    }
  }
  .toRight {
    writing-mode: vertical-rl;
    font-size: 20px;
    letter-spacing: 10px;
    color: #fff;
    background-color: #fff;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    line-height: 35px;
    // font-weight: 600;
    // border: 1px solid #e11;

    // box-shadow: 2px 0px 5px 0px rgb(138, 141, 147, 0.5);
    box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    margin-right: 15px;
    transform: rotate(90deg);
    text-align: center;
  }

  .toLeftDiv {
    display: none;
    // border: 1px solid #e11;
    // text-align: center;
    float: left;
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    margin-right: 20px;
    position: relative;
    // box-shadow: 2px 0px 5px 0px rgb(138, 141, 147, 0.5);
    box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.11);

    .jiao {
      position: absolute;
      top: 83px;
      right: -8px;
      width: 15px;
      height: 15px;
      // border: 1px solid #e11;
      transform: rotate(45deg);
      background-color: #fff;
      // border-right: 1px solid rgba(0, 0, 0, 0.11);
      // border-top: 1px solid rgba(0, 0, 0, 0.11);
      // box-shadow: 10px  1px 4px 10px rgba(0, 0, 0, 0.11);
    }

    .one {
      font-size: 14px;
      color: #333;
      font-weight: 600;
      i {
        transform: rotate(90deg);
        font-size: 20px;
        vertical-align: text-top;
      }

      span {
        padding-left: 20px;
      }
      clear: both;
    }
    .two {
      color: #999999;
      font-size: 14px;
      padding-top: 10px;
      padding-left: 40px;
      padding-bottom: 10px;

      clear: both;
    }
    .three {
      // i {
      //   font-size: 20px;
      //   color: green;
      // }
      img {
        // margin-left: 40px;
        width: 142px;
        border-radius: 5px;
      }
    }
  }
}

.toMe:hover {
  .toLeftDiv {
    display: block;
  }
}
</style>
