<template>
  <div class="swiper-container" id="swiper" v-if="lunbo.length > 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, i) in lunbo" :key="i">
        <!-- <img src="../assets/img/1.jpg" alt="" /> -->
        <a :href="item.linkurl" target="_blank">
          <img :src="setLogo(item.img1)" alt="" class="banner" />
        </a>
      </div>
      <slot name="slid"></slot>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev">
      <a-icon type="left" />
    </div>
    <div class="swiper-button-next">
      <a-icon type="right" />
    </div>

    <!-- 如果需要滚动条 -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>
 
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  props: {
    lunbo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperDOM: null,
    };
  },
  watch: {
    lunbo: {
      handler(val) {
        console.log("lunbo-0", val);
        this.lunbo = val;
        this.$nextTick(() => {
          console.log("$nextTick");
          this.newSwiperFn();
        });
      },
      // 初次监听即执行
      immediate: true,
      // 值改变时执行监听
      deep: true,
    },
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    newSwiperFn() {
      new Swiper(".swiper-container", {
        // direction: "horizontal", // 垂直切换选项
        //mousewheel: true, //滚轮
          //自动开始
        autoplay: {
          delay: 3000, //时间间隔
          disableOnInteraction: true, //*手动操作轮播图后不会暂停*
        },
        // loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 如果需要滚动条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },
      });
    },
  },
  components: {},
  created() {},
  mounted() {},
};
</script>
 
<style scoped lang="less">
.swiper-pagination {
  position: absolute;
  bottom: -0.1rem;
  right: 1rem;
}

.swiper-container ::v-deep .swiper-pagination-bullet-active {
  background-color: #fff;
}
.swiper-container ::v-deep .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}

#swiper {
  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    font-size: 36px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    display: none;
  }
  .swiper-button-prev {
    left: 100px;
  }
  .swiper-button-next {
    right: 100px;
  }
  .swiper-pagination {
    bottom: 0.5rem;
  }
  .swiper-slide {
    img {
      // height: 420px;
      width: 100%;
    }
  }
}
#swiper:hover {
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }
}
</style>