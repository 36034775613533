<template>
  <div>
    <m-Header2 :catname="catname" />

    <div class="mlist">
      <!-- <div v-if="list.length <= 0" class="noData">
        <img src="../../assets/img/noData.png" alt="" srcset="" />
      </div> -->
      <van-search
        v-model="query.keyword"
        placeholder="请输入关键词搜索"
        @input="onSearch"
        @clear="onClear"
      />
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '没有更多了' : '暂无数据'"
        @load="onLoad"
        class="content"
      >
        <div
          class="list"
          v-for="(item, i) in list"
          :key="i"
          @click="toDeatile(item.id)"
        >
          <img :src="setLogo(item.img1)" alt="" />
          <div class="right">
            <p class="up">
              {{ item.title }}
            </p>
            <p class="bottom">
              {{ item.sendtime }}
            </p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import mHeader2 from "./mHeader2.vue";

export default {
  components: {
    mHeader2,
  },
  name: "mlist",
  created() {
    // console.log("process.env.VUE_APP_API_BASE_URL");
    // console.log(process.env.VUE_APP_API_BASE_URL);

    console.log("this.$route.query-created-112-1");
    console.log("this.$route.query-created");
    console.log(this.$route.query);
    this.query = this.$route.query;
    this.catname = this.$route.query.catname;
  },
  //监听路由变化
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        console.log("newVal");
        console.log(newVal);
        console.log("oldVal");
        console.log(oldVal);
        this.list = [];
        this.page = 0;
        this.query = newVal;
        this.catname = newVal.catname;
        this.onLoad();
      },
      deep: true,
    },
  },
  data() {
    return {
      query: {},
      list: [],
      listName: "",
      // 当前页面
      page: 0,
      // 总页数
      totalpage: 1,

      loading: false,
      finished: false,

      flag: true,
      catname: "",
    };
  },
  methods: {
    onSearch(val) {
      this.query.keyword = val;
      this.page = 1
      this.getList();
    },
    onClear(){
      this.query.keyword = ''
      this.page = 1
      this.getList();
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    toDeatile(id) {
      console.log("toDeatile");
      this.$router.push({
        path: "/mdetaile",
        query: { id: id, catname: this.catname },
      });
    },
    getList() {
      this.flag = false;
      console.log("getList");
      this.query.page = this.page;

      this.$api.getAction("/index", this.query).then((res) => {
        console.log("res--getList");
        console.log(res);
        if (res.code == 0) {
          if(this.query.page <= 1){
            this.list = res.data.list
          }else{
            this.list = this.list.concat(res.data.list);
          }

          this.listName = res.data.description;
          this.total = parseInt(res.data.pagearr.total);
          this.totalPage = parseInt(res.data.pagearr.totalpage);

          this.loading = false;

          if (this.page >= this.totalPage) {
            this.finished = true;
          }
          this.flag = true;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onLoad() {
      if (this.flag) {
        this.page += 1;
        this.getList();
      }
    },
  },
};
</script>


<style scoped lang="less">
ul {
  padding: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  .name {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .more {
    font-size: 12px;
  }
}

.mlist {
  overflow: hidden;
  padding: 85px 20px 20px;

  .list {
    padding: 10px 0;
    overflow: hidden;
  }
  .noData {
    display: flex;
    justify-content: center;

    img {
      width: auto;
      height: auto;
    }
  }
  img {
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }

  div.right {
    padding-left: 100px;
    p.bottom {
      color: #666666;
      font-size: 14px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    p.up {
      font-size: 16px;
      padding-top: 5px;
      padding-bottom: 5px;

      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      height: 55px;
    }
  }
}
.item {
  height: 100px;
}
</style> 
