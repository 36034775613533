<template>
  <div id="mhome">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "mhome",
  components: {},
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>


<style scoped lang="less">
</style> 
