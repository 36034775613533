<template>
  <div id="kemu">
    <div class="bodyMain">
      <div class="title">
        {{ kemu.title }}
        <div class="gradient"></div>
        <!-- <span class="more">更多 <a-icon type="arrow-right" /></span> -->
      </div>

      <SwiperCom :stype="1" :content="kemu.content" :title="kemu.title"/>
    </div>
  </div>
</template>

<script>
import SwiperCom from "../components/swiperCom.vue";

export default {
  name: "kemu",
  components: {
    SwiperCom,
  },
  props: {
    kemu: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="less">
</style>
