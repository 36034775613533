<template>
  <div id="footerLayOut">
    <div class="footercontent">
      <div class="footercontentleft">
        <img :src="setLogo(webconfig.footer1)" alt="" />
      </div>
      <div class="footercontentcenter">
        <div>{{ webconfig.copyright }}</div>
        <div class="mt20">{{ webconfig.address }} {{ webconfig.link1 }}</div>
        <div class="mt20">
          <a href="https://beian.miit.gov.cn">{{ webconfig.indexabout }}</a>
        </div>
        <div
          class="mt20"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="el-image" style="width: 16px; margin-right: 4px">
            <img
              src="../assets/img/gong.png"
              class="el-image__inner"
              style="object-fit: contain; width: 16px; height: 16px"
            />
          </div>
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=61011302001931"
            >陕公网安备61011302001931</a
          >
        </div>
      </div>
      <div class="footercontentright">
        <img :src="setLogo(webconfig.footer2)" alt="" />
      </div>
    </div>
    <!-- <div class="bottomImge">
      <img :src="setLogo(webconfig.footer)" alt="" />
    </div> -->
    <!-- <div class="bottom">
      <a href="https://beian.miit.gov.cn">{{ webconfig.indexabout }}</a>
      <div>
        {{ webconfig.copyright }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "footerLayOut",
  props: {
    webconfig: Object,
  },
  data() {
    return {
      footerList: [],
    };
  },
  created() {
    this.getFooter();
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    getFooter() {
      this.$api.getAction("/youqinglink").then((res) => {
        if (res.code == 0) {
          this.footerList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#footerLayOut {
  text-align: center;
  // border: 1px solid;
  // padding-top: 30px;
  // color: #babcc1;
  // position: fixed;
  // bottom: 0;
  // width: 100%;

  .top {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    color: #666;

    div {
      padding: 0 20px;
      border-right: 1px solid #babcc1;
    }
    div:nth-child(3) {
      border-right: 0;
    }
  }
  .bottomImge {
    img {
      width: 100%;
      // height: 300px;
    }
  }
  .bottom {
    padding: 20px 0;
    background-color: rgba(38, 38, 38);
    font-size: 12px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: 600;
  }
}
.footercontent {
  width: 1321px;
  margin: 0 auto;
  display: flex;
  height: 155px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  .footercontentleft {
    width: 240px;
    img {
      width: 100%;
      height: 86px;
    }
  }
  .footercontentright {
    width: 240px;
    img {
      width: 100%;
      height: 86px;
    }
  }
  .footercontentcenter {
    width: 841px;
  }
}
.mt20 {
  margin-top: 16px;
}
</style>
