<template>
  <div>
    <m-Header2 :catname="catname" />
    <div id="mlistDown">
      <div class="mDown">
        <!-- <div class="title">
        <span class="name">{{ listName }}</span>
      </div> -->
      <van-search
        v-model="query.keyword"
        placeholder="请输入关键词搜索"
        @input="onSearch"
        @clear="onClear"
      />
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="list" v-for="(item, i) in list" :key="i">
            <div class="left">
              <p class="up">
                {{ item.title }}
              </p>
              <p class="down">{{ item.sendtime }}</p>
            </div>
            <div class="right">
              <van-button type="info" @click="aDownlad(item.file, item.title)">
                下载
                <!-- <a :href="setLogo(item.file)"></a> -->
              </van-button>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import mHeader2 from "./mHeader2.vue";

export default {
  components: {
    mHeader2,
  },
  name: "mlistDown",
  created() {
    console.log("this.$route.query-created");
    console.log(this.$route.query);
    this.query = this.$route.query;
    this.catname = this.$route.query.catname;
  },
  //监听路由变化
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        console.log("newVal");
        console.log(newVal);
        console.log("oldVal");
        console.log(oldVal);
        this.list = [];
        this.query = newVal;
        // this.getList();
        this.onLoad();
      },
      deep: true,
    },
  },
  data() {
    return {
      query: {},
      list: [],
      listName: "",
      // 当前页面
      page: 0,
      // 总页数
      totalpage: 0,

      loading: false,
      finished: false,

      flag: true,
      catname: "",
    };
  },
  methods: {
    onSearch(val) {
      this.query.keyword = val;
      this.page = 1
      this.getList();
    },
    onClear(){
      this.query.keyword = ''
      this.page = 1
      this.getList();
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    toDeatile(id) {
      console.log("toDeatile");
      this.$router.push({
        path: "/mdetaile",
        query: { id: id },
      });
    },
    getList() {
      console.log("getList");
      this.query.page = this.page;
      this.$api.getAction("/index", this.query).then((res) => {
        console.log("res--getList");
        console.log(res);
        if (res.code == 0) {
          if(this.query.page <= 1){
            this.list = res.data.list;
          }else{
            this.list = this.list.concat(res.data.list);
          }

          this.listName = res.data.description;
          this.total = parseInt(res.data.pagearr.total);
          this.totalPage = parseInt(res.data.pagearr.totalpage);

          this.loading = false;

          if (this.page >= this.totalPage) {
            this.finished = true;
          }

          this.flag = true;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onLoad() {
      if (this.flag) {
        this.page += 1;
        this.getList();
      }
    },

    // a标签下载文件，可跨域下载
    aDownlad(url, fileName) {
      console.log(url);
      console.log(fileName);
      if (url == "") {
        this.$toast.fail("没有对应文件");
        return;
      }
      const link = document.createElement("a");
      link.href = this.setLogo(url);
      // console.log("this.setLogo(url)");
      // console.log(this.setLogo(url));
      link.target = "_blank";
      link.download = fileName;
      link.style.display = "none";
      document.body.append(link);
      link.click();
    },
  },
};
</script>


<style scoped lang="less">
// #mlistDown {
// background: #eff1f3;
// }

ul {
  padding: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  .name {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #5e5e5e;
  }
  .more {
    font-size: 12px;
  }
}

.mDown {
  overflow: hidden;
  padding: 80px 20px 20px;
  .list {
    padding: 10px 0;
    overflow: hidden;

    div {
      float: left;
    }
    div.left {
      p.up {
        color: #333;
        width: 200px;
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p.down {
        font-size: 12px;
        padding-top: 5px;
      }
    }
    div.right {
      float: right;
    }
  }

  // div.right {
  // padding-left: 100px;
  // p.bottom {
  //   color: #666666;
  //   font-size: 14px;

  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   word-break: break-all;
  // }
  // p.up {
  //   font-size: 16px;
  //   padding-top: 5px;
  //   padding-bottom: 5px;

  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
  // }
}
.item {
  height: 100px;
}
</style> 
