import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import shouye from '@/views/shouye.vue'
import detaile from '@/views/detaile.vue'
import list from '@/views/list.vue'
import listDown from '@/views/listDown.vue'

import mhome from '@/views/mobile/mHome.vue'
import mindex from '@/views/mobile/mIndex.vue'
import mlist from '@/views/mobile/mList.vue'
import mlistDown from '@/views/mobile/mListDown.vue'
import mdetaile from '@/views/mobile/mDetaile.vue'

Vue.use(VueRouter)

console.log('router')
console.log('屏幕宽度' + screen.width)
console.log('页面可视宽度' + document.body.clientWidth)


let isMobile
//  1 为 PC ， 0 为 手机
if (document.body.clientWidth > 600) {
  sessionStorage.setItem('isMobile', 1)
  isMobile = 1
} else {
  sessionStorage.setItem('isMobile', 0)
  isMobile = 0
}

let routesPc = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/shouye',
    children: [
      {
        path: 'shouye',
        name: 'shouye',
        component: shouye,
      },
      {
        path: 'list',
        name: 'list',
        component: list,
      },
      {
        path: 'detaile',
        name: 'detaile',
        component: detaile,
      },
      {
        path: 'listDown',
        name: 'listDown',
        component: listDown,
      },
    ],
  }
]

let routesMob = [
  {
    path: '/',
    name: 'mhome',
    component: mhome,
    redirect: '/mindex',
    children: [
      {
        path: 'mindex',
        name: 'mindex',
        component: mindex,
      },
      {
        path: 'shouye',
        name: 'mindex',
        component: mindex,
      },
      {
        path: 'mlist',
        name: 'mlist',
        component: mlist,
      },
      {
        path: 'list',
        name: 'mlist',
        component: mlist,
      },
      {
        path: 'mlistDown',
        name: 'mlistDown',
        component: mlistDown,
      },
      {
        path: 'listDown',
        name: 'mlistDown',
        component: mlistDown,
      },
      {
        path: 'mdetaile',
        name: 'mdetaile',
        component: mdetaile,
      },
      {
        path: 'detaile',
        name: 'mdetaile',
        component: mdetaile,
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const routes = isMobile > 0 ? routesPc : routesMob


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
