<template>
  <div id="banner">
    <Swiper :lunbo="lunbo" v-if="!isMobile" />
    <swiper-m :lunbo="lunbo" v-else />
  </div>
</template>

<script>
import Swiper from "../components/swiper.vue";
import SwiperM from "../components/swiperM";

export default {
  name: "banner",
  components: {
    Swiper,
    SwiperM,
  },
  data() {
    return {
      lunbo: [],
      //  1 为 PC ， 0 为 手机
      isMobile: sessionStorage.getItem("isMobile") - 0 > 0 ? false : true,
    };
  },
  created() {
    // console.log("getBanner---");
    // console.log(sessionStorage.getItem("isMobile"));
    // console.log(typeof sessionStorage.getItem("isMobile"));
    // console.log(
    //   sessionStorage.getItem("isMobile") - 0 > 0 ? "/lunboban" : "/abc"
    // );
    //  1 为 PC ， 0 为 手机
    // sessionStorage.getItem("isMobile") - 0 > 0 ? "/lunboban" : "/abc"

    this.getBanner("/lunboban");
  },
  methods: {
    getBanner(val) {
      this.$api.getAction(val).then((res) => {
        if (res.code == 0) {
          this.lunbo = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
// #banner {
// }
</style>
