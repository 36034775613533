<template>
  <div id="qiye">
    <div class="bodyMain">
      <div class="title">
        {{ qiye.title }}
        <div class="gradient"></div>
        <span class="more" @click="goLink()"
          >更多 <a-icon type="arrow-right"
        /></span>
      </div>

      <div class="top">
        <div
          v-for="(item, i) in qiye.content.slice(0, 2)"
          :key="i"
          class="item"
          @click="toDeatile(item.id, qiye.title)"
        >
          <img :src="setLogo(item.img)" alt="" class="fangda" />
        </div>
      </div>
      <div class="bottom">
        <div
          v-for="(item2, index) in qiye.content.slice(2, 5)"
          :key="index"
          class="item"
          @click="toDeatile(item2.id, qiye.title)"
        >
          <img :src="setLogo(item2.img)" alt="" class="fangda" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toDeatile } from "../assets/js/fun";
export default {
  name: "qiye",
  components: {},
  props: {
    qiye: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toDeatile,
    goLink() {
      this.$router.push({
        path: "/list",
        query: { pid: this.qiye.pid, ty: this.qiye.ty },
      });
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
  },
};
</script>

<style scoped lang="less">
#qiye {
  background-color: #fff;

  .bodyMain {
    .top {
      display: flex;
      margin-bottom: 24px;

      div.item {
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        width: calc((100% - 24px) / 2);
        img {
          width: 100%;
          height: 234px;
        }
      }
      div.item:nth-child(1) {
        margin-right: 24px;
      }
    }
    .bottom {
      display: flex;

      div.item {
        overflow: hidden;
        margin-right: 24px;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;

        img {
          width: 100%;
          height: 234px;
        }
      }
      div:nth-child(3) {
        margin-right: 0;
      }
    }
  }
}
</style>
