<template>
  <div class="swiperCom">
    <div class="kemu">
      <a-carousel>
        <div class="">
          <div
            class="item toUp"
            v-for="(item, i) in content.slice(0, num)"
            :key="i"
            @click="toDeatile(item.id, title)"
          >
            <div class="imgDiv">
              <div class="imgDiv2">
                <img :src="setLogo(item.img)" alt="" />
              </div>
            </div>
            <span class="borderBottom" :title="item.title">{{ item.title }}</span>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
import { toDeatile } from "../assets/js/fun";
export default {
  name: "swiperCom",
  data() {
    return {
      num: 5,
      // num: document.body.clientWidth < 1800 ? 4 : 5,
    };
  },
  props: {
    stype: {
      type: Number,
      default: 0,
    },
    lunbo: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Array,
      default: () => [],
    },
    title:{
      type: String,
      default: ''
    }
  },
  methods: {
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    toDeatile,
    // toDeatile(id) {
    //   console.log(id);
    //   this.$router.push({ path: "/detaile", query: { id: id } });
    // },
  },
};
</script>

<style scoped lang="less">
.swiperCom {
  .ant-carousel > .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel > .slick-slide h3 {
    color: #fff;
  }

  .banner {
    height: 500px;
    width: 100%;
  }

  .kemu {
    .item {
      width: calc((100% - 80px) / 5);
      background-color: #fff;
      margin-right: 20px;
      border-radius: 8px;
      float: left;
      text-align: center;
      cursor: pointer;

      .borderBottom {
        font-weight: 600;
      }

      .imgDiv {
        width: 104px;
        height: 104px;
        border-radius: 55px;
        margin: 0 auto;
        margin-top: 40px;
        padding-top: 27px;

        .imgDiv2 {
          margin: 0 auto;
          width: 49px;
          height: 49px;
          border-radius: 9px;
          padding-top: 8px;

          img {
            margin: 0 auto;
            width: 33px;
            height: 33px;
            // border-radius: 9px;
          }
        }
      }
      p {
        padding: 20px 20px 0px;
        margin-bottom: 20px;
        color: #666666;
        font-size: 14px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .item:nth-child(5n) {
      margin-right: 0px;
    }

    // ::v-deep .slick-list {
    // padding-top: 20px;
    // }
  }
  .kemu {
    .item:hover {
      box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
