<template>
  <div id="listDown">
    <div class="bodyMain">
      <!-- <a-pagination :default-current="page" :total="total" @change="onChange" /> -->
      <!-- <div class="title">安全资讯</div> -->
      <a-input-search placeholder="请输入关键词搜索" @search="onSearch" style="margin-bottom:40px;width:400px;" allowClear/>
      <div v-for="(item, i) in arr" :key="i" class="all">
        <div class="rightCon">
          <div class="rightTitle">
            {{ item.title }}
          </div>
          <a-button @click="down(item.file)">
            点击下载
            <!-- <a :href="setLogo(item.file)" target="_blank"></a> -->
            <!-- <a :href="setLogo(item.file)" target="_blank">点击下载</a> -->
          </a-button>
          <div class="rightTime">
            {{ item.sendtime }}
          </div>
        </div>
      </div>

      <a-pagination
        :default-current="page"
        :total="total"
        @change="onChange"
        :hideOnSinglePage="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "listDown",
  components: {},
  data() {
    return {
      query: {
        keyword: ''
      },
      arr: [],
      page: 1,
      total: 10,
    };
  },
  //监听路由变化
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        console.log("newVal");
        console.log(newVal);
        console.log("oldVal");
        console.log(oldVal);
        this.query = newVal;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.query =Object.assign(this.$route.query, this.query);
    this.getList();
  },
  mounted() {},
  methods: {
    onSearch(val) {
      this.query.keyword = val
      this.getList()
    },
    down(url) {
      if (url == "") {
        this.$message.error("暂无对应文件");
      } else {
        window.open(this.setLogo(url), "_blank");
      }
    },
    onChange(cu) {
      console.log(cu);
      this.page = cu;
      this.query.page = this.page;
      this.getList();
    },
    setLogo(val) {
      return process.env.VUE_APP_PIC_URL + val;
    },
    toDetaile(item) {
      console.log(item);
      this.$router.push({ path: "/detaile", query: { id: item.id, catname: this.query.catname } });
    },
    getList() {
      this.$api.getAction("/index", this.query).then((res) => {
        console.log("res");
        console.log(res);
        if (res.code == 0) {
          this.arr = res.data.list;
          this.total = parseInt(res.data.pagearr.total);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
#listDown {
  .bodyMain {
    margin: 0 auto;

    .title {
      // font-size: 20px;
      font-weight: 600;
      // border-bottom: 1px solid #e8e8e8;
    }

    .all {
      display: flex;
      // cursor: pointer;
      margin-bottom: 20px;

      .rightCon {
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        height: 35px;
        line-height: 35px;

        .ant-btn {
          color: #fff;
        }

        .rightTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-size: 20px;
          // border: 1px solid;
          width: 70%;
          float: left;
        }

        .ant-btn {
          float: right;
        }

        .rightTime {
          width: 15%;
          // border: 1px solid;
          float: right;
        }
      }
    }

    .ant-pagination {
      text-align: right;
    }
  }
}
</style>
